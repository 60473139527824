import { defineStore } from "pinia";
import { _ } from "lodash";

import { usePage } from "@inertiajs/vue3";
const page = usePage();
export const useDocumentStore = defineStore("document", {
    state: () => ({
        doc: {},
        showSettings: false,
        showProducts: false,
        showClients: false,
        productType: "single",
        showSurface: false,
        editLine: null,
        discount: 0,
    }),
    getters: {
        hasDiscount() {
            //check if one of the lines has a discount
            let hasDiscount = false;
            if (_.size(_.get(this.doc, "groups", []))) {
                this.doc.groups.forEach((group) => {
                    group.lines.forEach((line) => {
                        if (line.discount) hasDiscount = true;
                    });
                });
            }
            return hasDiscount;
        },
        hasReference() {
            if (_.has(page.props, "models.data")) {
                return page.props.models.data.some((model) => model.reference);
            } else if (_.has(page.props, "tabValues.models.data")) {
                return page.props.tabValues.models.data.some(
                    (model) => model.reference,
                );
            }
            return false;
        },
        totalLinesDiscount() {
            let discount = 0;
            if (_.size(_.get(this.doc, "groups", []))) {
                this.doc.groups.forEach((group) => {
                    group.lines.forEach((line) => {
                        if (line.discount == "percentage") {
                            discount +=
                                _.toNumber(line.unit_price) *
                                _.toNumber(line.amount) *
                                (_.toNumber(line.discount_percentage) / 100);
                        } else if (line.discount == "amount") {
                            discount += _.toNumber(line.discount_amount);
                        }
                    });
                });
            }
            return discount;
        },
        totalInclCashDiscount() {
            return (
                ((_.toNumber(this.doc.total_amount) -
                    _.toNumber(this.doc.vat_amount)) *
                    (100 -
                        _.toNumber(
                            _.get(this.doc, "cash_discount_percentage", 0),
                        ))) /
                    100 +
                _.toNumber(this.doc.vat_amount)
            );
        },
        netDiscount() {
            return (
                _.toNumber(this.doc.net_amount) *
                (_.toNumber(this.doc.discount_percentage) / 100)
            );
        },
    },
    actions: {
        formatNumber(number, withCurrency = true) {
            if (number == null) return "";
            if (!withCurrency)
                return number.toLocaleString("nl-NL", {
                    minimumFractionDigits: 2,
                });
            return new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            }).format(number);
        },
        setDocument(doc) {
            doc.deleted_lines = [];
            this.doc = doc;
            this.calculateTotals();
        },
        changeType(line, type) {
            //remove price if type is composite, text or title
            if (type === "composite" || type === "text" || type === "title") {
                line.unit_price = 0;
            }
            if (type === "fixed") {
                line.amount = 1;
            }
            if (type === "surface") {
                this.editLine = line;
                this.showSurface = true;
            }
            line.type = type;
        },
        closeSurface(line) {
            let props = _.find(this.doc.groups, (group) => {
                return _.find(group.lines, { uuid: line.uuid });
            });
            props.line.amount = surface.value;
            props.line.product_id = form.product_id;
            props.line.product = _.find(products.value, {
                key: form.product_id,
            });
            props.line.description = form.description;
        },
        deleteLine(group, line, isGroup = false) {
            if (isGroup) {
                group.lines.forEach((line) => {
                    this.doc.deleted_lines.push(line["uuid"]);
                });
                let groupIndex = this.doc.groups.indexOf(group);
                this.doc.groups.splice(groupIndex, 1);
            } else {
                this.doc.deleted_lines.push(line["uuid"]);
                let index = group.lines.indexOf(line);
                group.lines.splice(index, 1);
            }

            //if group has no lines, delete group
            if (group.lines.length === 0) {
                let groupIndex = this.doc.groups.indexOf(group);
                this.doc.groups.splice(groupIndex, 1);
            }
            this.calculateTotals();
        },
        duplicateLine(group, line, isGroup = false) {
            const newLine = clone(line);
            newLine.uuid = self.crypto.randomUUID();
            if (isGroup) {
                //copy the group
                let newGroup = clone(group);
                newGroup.uuid = self.crypto.randomUUID();

                //copy the lines
                newGroup.lines = [];
                group.lines.forEach((line) => {
                    let newLine = clone(line);
                    newLine.uuid = self.crypto.randomUUID();
                    newGroup.lines.push(newLine);
                });

                this.doc.groups.push(newGroup);
            } else if (group.type === "composite") {
                group.lines.push(newLine);
            } else {
                this.doc.groups.push({
                    uuid: self.crypto.randomUUID(),
                    type: "pieces",
                    description: "",
                    lines: [newLine],
                });
            }
            this.calculateTotals();
        },
        addLine(group, line) {
            let vat_percentage = _.last(group.lines).vat_percentage;
            if (_.isNil(vat_percentage)) {
                vat_percentage = 21;
            }
            const newLine = {
                uuid: self.crypto.randomUUID(),
                type: "pieces",
                description: "",
                amount: 1,
                unit_price: 0,
                place:
                    group.type == "composite"
                        ? group.lines.length + 1
                        : this.doc.groups.length + 1,
                vat_percentage: vat_percentage,
                net_price: 0,
            };

            if (line.type !== "composite" && group.type === "composite") {
                group.lines.push(newLine);
            } else {
                this.doc.groups.push({
                    uuid: self.crypto.randomUUID(),
                    type: "pieces",
                    description: "",
                    place: this.doc.groups.length + 1,
                    lines: [newLine],
                });
            }
        },
        addProductToLines(data) {
            if (
                this.doc.groups.length === 1 &&
                this.doc.groups[0].lines.length === 1 &&
                this.doc.groups[0].lines[0].description === ""
            ) {
                this.doc.groups = [];
            }
            this.doc.groups.push({
                uuid: self.crypto.randomUUID(),
                type: data.group.type,
                title: data.group.title,
                place: data.group.place,
                lines: data.lines,
            });
            this.calculateTotals();
        },
        calculateLine(line) {
            let discount_amount = 0;
            let amount = line.amount;
            if (line.type == "time") {
                amount = ((line.amount / 3600) * 100) / 100;
            }
            if (line.discount == "percentage") {
                discount_amount =
                    line.unit_price *
                    amount *
                    (_.toNumber(line.discount_percentage) / 100);
            } else if (line.discount == "amount") {
                discount_amount = _.toNumber(line.discount_amount);
            }
            if (_.toNumber(this.doc.excl_vat)) {
                line.net_price = Math.round(
                    _.toNumber(line.unit_price) * amount,
                );
                line.net_discounted_price =
                    Math.round(
                        (line.net_price - discount_amount + Number.EPSILON) *
                            100,
                    ) / 100;
                line.vat_amount =
                    Math.round(
                        (line.net_price *
                            (_.toNumber(line.vat_percentage) / 100) +
                            Number.EPSILON) *
                            100,
                    ) / 100;
                line.vat_discounted_amount =
                    Math.round(
                        (line.net_discounted_price *
                            (_.toNumber(line.vat_percentage) / 100) +
                            Number.EPSILON) *
                            100,
                    ) / 100;
                line.total_price = line.net_price + line.vat_amount;
            } else {
                line.total_price =
                    Math.round(
                        (amount * line.unit_price + Number.EPSILON) * 100,
                    ) / 100;
                line.total_price = line.total_price - discount_amount;
                line.vat_amount =
                    Math.round(
                        ((line.total_price / (line.vat_percentage + 100)) *
                            line.vat_percentage +
                            Number.EPSILON) *
                            100,
                    ) / 100;
                line.net_price = line.total_price - line.vat_amount;
                line.net_discounted_price =
                    Math.round(
                        (line.total_price - discount_amount + Number.EPSILON) *
                            100,
                    ) / 100;
            }
            line.total_discounted_price =
                Math.round((line.unit_price * amount - discount_amount) * 100) /
                100;

            this.calculateTotals();
        },
        calculateTotals() {
            let sum = 0;
            let vat = 0;
            if (this.doc.excl_vat) {
                this.doc.groups.forEach((group) => {
                    let group_sum = 0;
                    group.lines.forEach((line) => {
                        vat +=
                            line.net_discounted_price *
                            (line.vat_percentage / 100);
                        if (line.type != "composite")
                            group_sum += line.net_discounted_price;
                        group.net_amount = group_sum;
                        sum += group_sum;
                    });
                });
                this.doc.net_amount = sum;
            } else {
                this.doc.groups.forEach((group) => {
                    group.lines.forEach((line) => {
                        vat +=
                            (line.total_discounted_price *
                                line.vat_percentage) /
                            (100 + line.vat_percentage);
                        sum += line.total_discounted_price;
                    });
                });
                this.doc.total_amount = sum;
                this.doc.net_amount = sum - vat;
            }
            if (
                this.doc.discount == "percentage" &&
                this.doc.discount_percentage > 0
            ) {
                this.discount =
                    this.doc.net_amount * (this.doc.discount_percentage / 100);
                this.doc.net_amount -= this.discount;
                vat = vat * ((100 - this.doc.discount_percentage) / 100);
            }
            if (
                _.toNumber(this.doc.cash_discount) &&
                _.toNumber(this.doc.cash_discount_percentage) > 0
            ) {
                vat =
                    vat *
                    ((100 - _.toNumber(this.doc.cash_discount_percentage)) /
                        100);
            }

            this.doc.vat_amount = vat;
            this.doc.total_amount = this.doc.net_amount + this.doc.vat_amount;
        },
        sortElements(elements) {
            elements.forEach((item, index) => {
                item.place = index + 1;
            });
        },
        changeDiscount(line, discount) {
            if (discount != "no") {
                line.discount = discount;
            } else {
                line.discount = null;
                line.discount_percentage = 0;
                line.discount_amount = 0;
            }
            this.calculateLine(line);
        },
        changeVatAll(vat) {
            this.doc.groups.forEach((group) => {
                group.lines.forEach((line) => {
                    line.vat_percentage = vat;
                });
            });
        },
        updateLines() {
            this.doc.groups.forEach((group) => {
                group.lines.forEach((line) => {
                    this.calculateLine(line);
                });
            });
        },
        addProduct(group, line) {
            this.productType = "single";
            this.showProducts = true;
        },
        addComposedProduct(group, line) {
            this.productType = "composed";
            this.showProducts = true;
        },
        saveSettings(settingsForm = null) {
            if (settingsForm) {
                _.each(settingsForm, (value, key) => {
                    this.doc[key] = value;
                    if (
                        key === "change_vat_value_all_lines" &&
                        value !== null
                    ) {
                        this.changeVatAll(value);
                    }
                    this.updateLines();
                });
            }
            this.showSettings = false;
            this.calculateTotals();
            this.calculateExpireDate();
        },
        calculateExpireDate() {
            try {
                const date = new Date(this.doc.invoice_date);
                if (!isNaN(date.getTime())) {
                    if (parseInt(this.doc.expire_days, 10) > 365) {
                        const strExpireDays = this.doc.expire_days.toString();
                        if (strExpireDays.startsWith("100")) {
                            const extraDays = parseInt(
                                strExpireDays.slice(3),
                                10,
                            );
                            const endOfMonth = new Date(
                                date.getFullYear(),
                                date.getMonth() + 1,
                                0,
                            );
                            endOfMonth.setDate(
                                endOfMonth.getDate() + (extraDays + 1),
                            );
                            this.doc.expire_date = endOfMonth
                                .toISOString()
                                .split("T")[0];
                        }
                    } else {
                        date.setDate(
                            date.getDate() + parseInt(this.doc.expire_days),
                        );
                        this.doc.expire_date = date.toISOString().split("T")[0];
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        addClient(data) {
            this.doc.client_id = _.get(data, "client.key", null);
            this.doc.client_contact_id = _.get(
                data,
                "client_contact.key",
                null,
            );
            this.doc.client = _.get(data, "client", null);
            this.doc.client_contact = _.get(data, "client_contact", null);
            this.doc.vat = _.join(
                [
                    _.get(data, "client.vat_country_code", null),
                    _.get(data, "client.vat_id", null),
                ],
                " ",
            );

            this.doc.client = data.client;
            this.doc.client_contact = data.client_contact;
            this.showClients = false;
        },
    },
});
